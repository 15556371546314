var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"tender-list"}},[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('div',[_vm._v(_vm._s(_vm.$t('users.userList')))]),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('router-link',{attrs:{"to":{name: 'admin-user-create'}}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"success"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v(_vm._s(_vm.$t('users.create')))])],1)],1)],1)]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"tender-search me-3 mb-4",attrs:{"placeholder":_vm.$t('general.typeToSearch'),"outlined":"","hide-details":"","dense":""},on:{"change":_vm.getDataFromApi},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"placeholder":_vm.$t('general.statusFilter'),"items":_vm.statusOptions,"item-value":"key","item-text":function (item) { return _vm.$t(item.name); },"outlined":"","dense":"","clearable":"","hide-details":""},on:{"change":_vm.getDataFromApi},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"items-per-page":15,"options":_vm.options,"server-items-length":_vm.totalUsers,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'admin-user-edit', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.first_name)+" ")])],1)])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'admin-user-edit', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.last_name)+" ")])],1)])]}},{key:"item.company.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.company.name)+" ")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'admin-user-edit', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.email)+" ")])],1)])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.phone))])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{name:'admin-user-edit',params:{id:item.id}},"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentEditOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('general.edit')))])],1)],1),(!item.deleted_at)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.preventDefault();return _vm.deleteUser(item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('general.delete')))])],1)],1):_vm._e(),(item.deleted_at)?_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){$event.preventDefault();return _vm.restoreUser(item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileRestoreOutline)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('general.restore')))])],1)],1):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="tender-list">
    <v-card>
      <v-card-title class="d-flex justify-space-between flex-wrap">
        <div>{{ $t('users.userList') }}</div>

        <div class="d-flex align-center flex-wrap">
          <router-link :to="{name: 'admin-user-create'}">
            <v-btn
              color="success"
              class="mb-4 me-3"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>{{ $t('users.create') }}</span>
            </v-btn>
          </router-link>
        </div>
      </v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="$t('general.typeToSearch')"
            outlined
            hide-details
            dense
            class="tender-search me-3 mb-4"
            @change="getDataFromApi"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            :placeholder="$t('general.statusFilter')"
            :items="statusOptions"
            item-value="key"
            :item-text="item => $t(item.name)"
            outlined
            dense
            clearable
            hide-details
            @change="getDataFromApi"
          ></v-select>
        </v-col>
      </v-row>

      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="userListTable"
        :items-per-page="15"
        :options.sync="options"
        :server-items-length="totalUsers"
        :loading="loading"
      >
        <template #[`item.first_name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.first_name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.last_name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.last_name }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.company.name`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              {{ item.company.name }}
            </div>
          </div>
        </template>

        <template #[`item.email`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'admin-user-edit', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.email }}
              </router-link>
            </div>
          </div>
        </template>

        <template #[`item.phone`]="{item}">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.phone }}</span>
          </div>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            class="v-chip-light-bg font-weight-semibold text-capitalize"
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'admin-user-edit',params:{id:item.id}}"
                link
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                  <span>{{ $t('general.edit') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="!item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="deleteUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>{{ $t('general.delete') }}</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.deleted_at"
                link
              >
                <v-list-item-title @click.prevent="restoreUser(item.id)">
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileRestoreOutline }}
                  </v-icon>
                  <span>{{ $t('general.restore') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentEditOutline,
  mdiFileDocumentOutline,
  mdiFileRestoreOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'

// sidebar
import axiosIns from '@axios'
import statusService from '@/views/shared/statusService'

export default {
  components: {
  },
  data() {
    const selectedRows = []
    const userListTable = []
    const totalUsers = 0
    const loading = true
    const options = {}

    const tableColumns = [
      { text: 'FIRST NAME', value: 'first_name' },
      { text: 'LAST NAME', value: 'last_name' },
      { text: 'COMPANY', value: 'company.name', sortable: false },
      { text: 'EMAIL', value: 'email' },
      { text: 'PHONE', value: 'phone' },
      { text: 'STATUS', value: 'status' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: true,
      },
    ]

    const statusFilter = ''

    const statusOptions = this.$store.getters.statusesWithTrashed

    const searchQuery = ''

    return {
      options,
      loading,
      searchQuery,
      statusFilter,
      tableColumns,
      userListTable,
      selectedRows,
      totalUsers,
      statusOptions,
      icons: {
        mdiFileDocumentEditOutline,
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiFileRestoreOutline,
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    this.resolveStatusVariant = statusService.resolveStatusVariant.bind(this)
  },

  methods: {
    getDataFromApi() {
      this.loading = true
      const orderBy = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'id'
      const orderDirection = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : 'true'
      const search = this.searchQuery ?? ''
      const status = this.statusFilter ?? ''
      axiosIns.get(`/admin/users?page=${this.options.page}&perPage=
      ${this.options.itemsPerPage}&orderBy=${orderBy}&orderDirection=${orderDirection}&search=${search}&status=${status}`)
        .then(res => {
          this.totalUsers = res.data.users.total
          this.userListTable = res.data.users.data
          this.loading = false
        })
        .catch()
    },
    deleteUser(id) {
      axiosIns.delete(`/admin/users/${id}`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
    restoreUser(id) {
      axiosIns.put(`/admin/users/${id}/restore`)
        .then(() => {
          this.getDataFromApi()
        })
        .catch()
    },
  },
}
</script>

<style lang="scss">
</style>
